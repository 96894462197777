<template>
  <el-dialog
    v-loading="loading"
    class="r-modal-window"
    title="Информация о грузе"
    :visible="isVisible"
    :before-close="close"
    width="640px"
    top="50px"
    destroy-on-close
  >
    <r-block
      v-loading="loading"
      no-padding
    >
      <attributes-list
        :model="model"
        :related="related"
        :source="cargo"
      />
    </r-block>
  </el-dialog>
</template>

<script>
import { cargoModel } from '../../configs'

export default {
  components: {
    attributesList: () => import('@/components/globals/r-modal-elements/attributes-list')
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    cargo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      model: cargoModel
    }
  },
  computed: {
    related() {
      return this.$store.state.unload.related
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
